import clsx from 'clsx';
import { IconProps } from './types';

export const Icon = (props: IconProps) => {
  const { name, size = 18, className, ...rest } = props;

  return (
    <i
      className={clsx(`flex pz-icon-${name}`, className)}
      {...rest}
      style={{
        fontSize: `${size}px`
      }}
    />
  );
};
