import { forwardRef } from 'react';
import { SelectProps } from './types';
import clsx from 'clsx';
import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    className,
    options,
    borderless = false,
    icon,
    iconSize,
    error,
    label,
    labelStyle,
    labelClassName,
    required = false,
    ...rest
  } = props;
  const hasFloatingLabel = label && labelStyle === 'floating';

  return (
    <label
      className={clsx('relative flex flex-col text-[11px] text-primary-400', {
        'pl-7': icon
      })}
    >
      {icon && (
        <Icon
          name={icon}
          size={iconSize ?? 20}
          className="absolute left-0 top-1/2 -translate-y-1/2 transform"
        />
      )}

      {label && (
        <span
          className={twMerge(
            clsx(
              'text-[11px] uppercase font-sans text-primary-400',
              {
                'absolute left-0 top-0 pointer-events-none transition-all transform -translate-y-1/2 text-[11px] uppercase font-serif text-primary-400 mb-5':
                  hasFloatingLabel
              },
              { 'mb-5': !hasFloatingLabel },
              labelClassName
            )
          )}
        >
          {label} {required && <span className="text-red">*</span>}
        </span>
      )}

      <div className="relative">
        <select
          {...rest}
          ref={ref}
          className={twMerge(
            clsx(
              'w-full shrink-0 cursor-pointer appearance-none truncate bg-transparent px-4 h-12 font-sans text-[11px] leading-3 text-primary outline-none',
              { 'pt-3 border-0 border-b': hasFloatingLabel },
              !borderless &&
                'border border-gray transition-all duration-150 hover:border-primary',
              error
                ? 'border-error focus:border-error'
                : 'border-gray hover:border-black focus:border-black'
            ),
            className
          )}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              className={option.class}
            >
              {option.label}
            </option>
          ))}
        </select>

        <Icon
          name="chevron-down"
          size={8}
          className="pointer-events-none absolute end-3 top-1/2 -translate-y-1/2 transform text-black"
        />
      </div>

      {error && (
        <span className="text-error mt-1 text-xs">{error.message}</span>
      )}
    </label>
  );
});

Select.displayName = 'Select';

export { Select };
