import { forwardRef, useState, useEffect } from 'react';
import { RadioProps } from './types';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { useMediaQuery } from '@akinon/next/hooks';

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    children,
    inputClassName,
    inputWidth = 10,
    inputHeight = 10,
    ...rest
  } = props;

  const matches = useMediaQuery('(min-width: 1024px)');

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    width: `${inputWidth}px`,
    height: `${inputHeight}px`
  };

  if (Array.isArray(inputWidth)) {
    if (!isDesktop) {
      style['width'] = `${inputWidth[0]}px`;
    } else {
      style['width'] = `${inputWidth[1]}px`;
    }
  } else {
    style['width'] = `${inputWidth}px`;
  }

  if (Array.isArray(inputHeight)) {
    if (!isDesktop) {
      style['height'] = `${inputHeight[0]}px`;
    } else {
      style['height'] = `${inputHeight[1]}px`;
    }
  } else {
    style['height'] = `${inputHeight}px`;
  }

  return (
    <label className={twMerge('flex items-center text-xs', props.className)}>
      <input
        style={style}
        type="radio"
        {...rest}
        ref={ref}
        className={twMerge(
          clsx(
            'appearance-none border border-gray rounded-full',
            'checked:bg-primary checked:ring-1 checked:ring-primary',
            inputClassName
          )
        )}
      />
      {children && <span className="text-xs ml-2">{children}</span>}
    </label>
  );
});

Radio.displayName = 'Radio';

export { Radio };
