import { forwardRef, useState, useEffect } from 'react';
import { CheckboxProps } from './types';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { useMediaQuery } from '@akinon/next/hooks';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    children,
    error,
    inputClassName,
    inputWidth = 10,
    inputHeight = 10,
    ...rest
  } = props;

  const matches = useMediaQuery('(min-width: 1024px)');

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    width: `${inputWidth}px`,
    height: `${inputHeight}px`
  };

  if (Array.isArray(inputWidth)) {
    if (!isDesktop) {
      style['width'] = `${inputWidth[0]}px`;
    } else {
      style['width'] = `${inputWidth[1]}px`;
    }
  } else {
    style['width'] = `${inputWidth}px`;
  }

  if (Array.isArray(inputHeight)) {
    if (!isDesktop) {
      style['height'] = `${inputHeight[0]}px`;
    } else {
      style['height'] = `${inputHeight[1]}px`;
    }
  } else {
    style['height'] = `${inputHeight}px`;
  }

  return (
    <label
      className={twMerge('flex flex-col text-xs font-sans', props.className)}
    >
      <div className="flex items-center relative">
        <input
          style={style}
          type="checkbox"
          {...rest}
          ref={ref}
          className={twMerge(
            clsx(
              'appearance-none border border-gray rounded-none',
              'checked:bg-primary checked:ring-primary',
              props.appearance !== 'square' && [
                'appearance-none rounded-full',
                'checked:ring-1'
              ],
              props.appearance === 'square' && [
                'checked:relative checked:border-black',
                'checked:before:border-b checked:before:border-r checked:before:w-0.5 checked:before:h-1.5 checked:before:rotate-45 checked:before:text-white checked:before:absolute checked:before:left-[3px] checked:before:top-[0.5px]'
              ],
              inputClassName
            )
          )}
        />

        {children && <span className="ml-2">{children}</span>}
      </div>
      {error && (
        <span className="mt-1 text-sm text-error">{error.message}</span>
      )}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
