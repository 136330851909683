import clsx from 'clsx';
import { forwardRef, FocusEvent, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputProps } from './types';
import { twMerge } from 'tailwind-merge';

export const Input = forwardRef<
  HTMLInputElement,
  InputProps &
    Pick<
      NumberFormatProps,
      'format' | 'mask' | 'allowEmptyFormatting' | 'onValueChange'
    >
>((props, ref) => {
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const {
    id,
    label,
    labelStyle,
    labelClassName,
    wrapperClassName,
    error,
    mask,
    format,
    required = false,
    hideErrorMessage = false,
    ...rest
  } = props;
  const hasFloatingLabel = label && labelStyle === 'floating';
  const inputClass = twMerge(
    clsx(
      'text-[11px] font-sans text-primary-400 border px-4 h-12 placeholder:text-gray-600',
      'focus-visible:outline-none', // disable outline on focus
      { 'pt-3 border-0 border-b': hasFloatingLabel },
      error
        ? 'border-error focus:border-error'
        : 'border-gray hover:border-black focus:border-black'
    ),
    props.className
  );
  const inputProps: any = {
    id,
    ref,
    className: inputClass,
    onFocus: () => setFocused(true),
    onBlur: (event: FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      setHasValue(!!event.target.value);
    }
  };

  const Label = () => {
    if (!label) return null;

    return (
      <label
        htmlFor={id}
        className={twMerge(
          clsx(
            'text-[11px] uppercase font-sans text-primary-400',
            {
              'absolute left-0 rtl:left-auto rtl:right-0 top-0 pointer-events-none transition-all transform -translate-y-1/2':
                hasFloatingLabel
            },
            { 'mb-5': !hasFloatingLabel },
            { 'top-0': hasFloatingLabel && (focused || hasValue) },
            { 'top-0': !(hasFloatingLabel && (focused || hasValue)) },
            labelClassName
          )
        )}
      >
        {label} {required && <span className="text-error">*</span>}
      </label>
    );
  };

  return (
    <div className={twMerge(clsx('flex flex-col'), wrapperClassName)}>
      <div className="relative flex flex-col">
        {props.format ? (
          <>
            {labelStyle !== 'floating' && <Label />}
            <Controller
              name={props.name ?? ''}
              control={props.control}
              defaultValue={false}
              render={({ field }) => (
                <NumberFormat
                  format={format}
                  mask={mask ?? ''}
                  {...rest}
                  {...field}
                  {...inputProps}
                />
              )}
            />
            {labelStyle === 'floating' && <Label />}
          </>
        ) : (
          <>
            {labelStyle !== 'floating' && <Label />}
            <input {...rest} {...inputProps} />
            {labelStyle === 'floating' && <Label />}
          </>
        )}
      </div>
      {error && !hideErrorMessage && (
        <span className="mt-1 text-xs text-error">{error.message}</span>
      )}
    </div>
  );
});

Input.displayName = 'Input';
