'use client';

import { useLocalization } from '@akinon/next/hooks';
import { getCurrency } from '@akinon/next/utils';
import { useMemo } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import settings from 'settings';
import { PriceProps } from '../types';

export const Price = (props: NumberFormatProps & PriceProps) => {
  const {
    value,
    currencyCode,
    displayType = 'text',
    useCurrencySymbol = false,
    useNegative = false,
    useNegativeSpace = true,
    thousandSeparator = ',',
    decimalScale = 2,
    decimalSeparator = '.',
    fixedDecimalScale = true,
    ...rest
  } = props;
  const { currency: selectedCurrencyCode } = useLocalization();
  const currencyCode_ = currencyCode || selectedCurrencyCode;
  const _value = value;
  const { locale } = useLocalization();
  const localeValue = settings.localization.locales.find(
    (locale_) => locale_.value === locale
  );

  const currency = useMemo(
    () =>
      getCurrency({
        currencyCode: currencyCode_,
        useCurrencySymbol,
        useCurrencyAfterPrice: localeValue.rtl,
        useCurrencySpace: true
      }),
    [currencyCode_, useCurrencySymbol, localeValue]
  );

  return (
    <NumberFormat
      dir="ltr"
      value={useNegative ? `-${useNegativeSpace}${_value}` : _value}
      {...{
        [localeValue.rtl ? 'suffix' : 'prefix']: currency.toUpperCase()
      }}
      displayType={displayType}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={fixedDecimalScale}
      {...rest}
    />
  );
};
