'use client';

import { useMediaQuery } from '@akinon/next/hooks';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const Span = ({
  text,
  size = 11,
  letterSpacing,
  lineHeight,
  className = '',
  font = 'sans'
}: {
  text: string;
  size?: number | Array<number>;
  letterSpacing?: number | 'normal';
  lineHeight?: number;
  className?: string;
  font?: 'sans' | 'serif';
}) => {
  const matches = useMediaQuery('(min-width: 1024px)');

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    fontSize: `${size}px`,
    letterSpacing:
      typeof letterSpacing === 'number' ? `${letterSpacing}px` : letterSpacing
  };

  if (Array.isArray(size)) {
    if (!isDesktop) {
      style['fontSize'] = `${size[0]}px`;
    } else {
      style['fontSize'] = `${size[1]}px`;
    }
  } else {
    style['fontSize'] = `${size}px`;
  }

  if (lineHeight) {
    style['lineHeight'] = `${lineHeight}px`;
  }

  return (
    <span
      className={twMerge(
        `font-${font === 'serif' ? 'serif' : 'sans'} leading-none contents`,
        className
      )}
      style={style}
      dangerouslySetInnerHTML={{ __html: text }}
    ></span>
  );
};
